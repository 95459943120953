/* src/components/HeroSection.css */
.hero {
    background: url('./images/wallpaper.jpg') no-repeat center center/cover;
    color: #fff;
    padding: 100px 20px;
    text-align: center;
}

.hero-content h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.hero-content p {
    font-size: 1.2rem;
    max-width: 600px;
    margin: auto;
}
