/* src/App.css */
.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin: 0 10px;
}

nav ul li a {
  color: #61dafb;
  text-decoration: none;
}

.content-area {
  display: flex;
  justify-content: space-between;
}

main {
  flex-grow: 1;
  padding: 20px;
}

aside {
  width: 300px;
  padding: 20px;
}

.profile {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
}

.profile-image {
  width: 80%;
  height: auto;
  border-radius: 50%;
  margin-bottom: 20px;
}


/* Add additional styling as needed */
