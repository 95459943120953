/* Layout and spacing */
form {
    max-width: 500px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  div {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  /* Input and Textarea styles */
  input, textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Ensures padding does not affect overall width */
    font-size: 16px;
  }
  
  textarea {
    height: 100px;
    resize: vertical; /* Allows vertical resizing, good for longer messages */
  }
  
  /* Button styles */
  button {
    width: 100%;
    padding: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  /* Error message styles */
  div.error {
    color: red;
    font-size: 14px;
  }
  
  /* Focus styles for accessibility */
  input:focus, textarea:focus, button:focus {
    outline: 2px solid #0056b3;
  }
  
  /* Responsive adjustments */
  @media (max-width: 600px) {
    form {
      width: 90%;
      padding: 15px;
    }
  }
  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #fff;
    width: 14px;
    height: 14px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  