/* src/HomePage.css */
.home-page {
    padding: 20px;
    max-width: 800px;
    margin: auto;
  }
  
  .intro-section h1 {
    color: #007bff; /* Tech-blue, feel free to choose your color */
  }
  
  .intro-section p {
    font-size: 18px;
    line-height: 1.6;
  }
  
  .featured-posts {
    margin-top: 40px;
  }
  
  .featured-posts h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .post {
    background-color: #f9f9fa;
    border-left: 5px solid #007bff; /* A blue accent to signify tech/software */
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 5px;
  }
  
  .post h3 {
    margin-top: 0;
  }
  
  .post p {
    font-size: 16px;
    line-height: 1.5;
  }
  